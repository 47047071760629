export const GA_MEASUREMENT_ID: string = 'G-Q0WC80KEKD';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url: string): void => {
    if (typeof window.gtag !== 'undefined') {
        try {
            window.gtag('config', GA_MEASUREMENT_ID, { page_path: url });
        } catch (e) {
            console.error(e);
        }
    }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
    action,
    category,
    label,
    value,
}: {
    action: string;
    category: string;
    label: string;
    value: number;
}): void => {
    if (window.gtag !== undefined || window.gtag !== null) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value,
        });
    }
};
